import './moncss.css';
import React,  {Alert,Component} from 'react';
import { useMap } from 'react-leaflet/hooks'
import { MapContainer as LeafletMap, TileLayer, Circle, Tooltip, Popup,Marker} from "react-leaflet";
import L from "leaflet";
import { Redirect } from 'react-router-dom';
import { Nav,Navbar,Button,Form,Row,Modal,Col,table,thead,tr,th,tbody,td,Container} from 'react-bootstrap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import queryString from 'query-string';
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

const positionunsaid = [48.8615147, 2.38368];
const initialZoom = 8;

const markerunsaid = new L.icon({
  iconUrl: process.env.PUBLIC_URL + "marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

class Map extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      signin : true,
      modalinfo: false,
      numeroinscription:0,
      typeuser:'',
      idsocietycenter:0,
      idcenter:0,
      namecenter:'',
      latcenter:'',
      longcenter:'',
      typeadmin:false,
      wbslink:'',
      listcenter:[],
      loadingdata: true,
    };
        
  }
    
    async componentDidMount() {

        sessionStorage.removeItem( 'iconify-count');
        sessionStorage.removeItem( 'iconify-version');

        let wbslinkload = sessionStorage.getItem('wbs');
        this.setState({
          wbslink:wbslinkload
        })

        let token = sessionStorage.getItem('tokenunsaid');
      
      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);

      if(isMyTokenExpired == true){
        this.setState({ typeuser: null})
        document.location.href = '/login';
        return;
      }
      
      token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, ""); 

      const response = await fetch(this.state.wbslink + 'center',{                       
        mode:'cors',
        method:'get',
        headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
        });
      const data = await response.json();

      if(data.length > 0){

        this.setState({ listcenter: data})

      }else{
        this.setState({ loadershow: false})
      }

        document.title = "Listes des centres";

        window.scrollTo(0, 0);

        return;
        
        
    };

    async componentWillMount() {

      sessionStorage.removeItem( 'iconify-count');
      sessionStorage.removeItem( 'iconify-version');

      let wbslinkload = sessionStorage.getItem('wbs');
      this.setState({
        wbslink:wbslinkload
      })

      document.title = "Listes des centres";

      window.scrollTo(0, 0);
    
    let tokenuser = sessionStorage.getItem('tokenunsaid');

    const myDecodedToken = decodeToken(tokenuser);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(tokenuser);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
    
    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {
  
        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

          document.location.href = "/Buycredit";

        }else{

          this.setState({
            loadingdata:false
          })
          
        }
        
    })


      return;

      let idtype = parseInt(sessionStorage.getItem('typeuser'));

      if(idtype>2){
  
        let idsc = sessionStorage.getItem('idsociety');
  
        const response = await fetch(wbslinkload + 'societycenter/listcenterbysociety.php',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idsoc:idsc
          })});
  
        const datacenter = await response.json();
  
        if(JSON.stringify(datacenter).replace(/"/g, "") !== '0'){
          this.setState({ listcenter: datacenter})
        }
    
        }else{
          
          const responsesocietecentre = await fetch(wbslinkload + 'societycenter/listsocietycenter.php');
          const datasocietecentre = await responsesocietecentre.json();
          this.setState({ listcenter: datasocietecentre})
  
        }
  
    }

    handleClick (data,idsocietycenter,idsocietyowner,name,latitude,longitude) {

      this.setState({ 
        modalinfo: true,
        idsocietycenter:idsocietycenter,
        idcenter:idsocietyowner,
        namecenter:name,
        latcenter:latitude,
        longcenter:longitude,
      })

    }
   
    
  render() {


          return (

                <div className="Mapscreen" style={{backgroundColor:"#333333"}}>

                  {this.state.loadingdata == true?

                    null
                  
                    :

                    <div className="App" style={{marginTop:"60px", overflowY:"hidden" }}>

                        {this.state.signin == false ?
                            

                            <Redirect push to="/Login"/>
                              
                            :

                            null

                        }

                        {this.state.modalinfo === true ?

                        <Modal

                        show={this.state.modalinfo}
                        onHide={ () => this.setState({modalinfo:false})}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                        >
                          <Modal.Body>

                          <div className="d-flex flex-row justify-content-center">
                                          
                              <div className="d-flex flex-column" style={{margin:"20px"}}>
                                  
                                  <div className="d-flex flex-column align-items-center Modalbodynnl">

                                  <div className="d-flex flex-column align-items-center">
                                    <label>Centre : {this.state.namecenter}</label>
                                    <label>Latitude: {this.state.latcenter}</label>
                                    <label>Longitude:  {this.state.longcenter}</label>
                                  </div>
                                      
                                    <div className='row d-flex align-items-center'>
                                                  
                                      <Button className="btn" onClick={ () => this.setState({modalinfo:false})} variant="primary">Fermer</Button>
                                                                             
                                    </div>

                                  </div>
                                  
                              </div>

                            </div>    

                        </Modal.Body>
                        </Modal>

                        :

                        null

                        }

                  <LeafletMap
                        center={positionunsaid}
                        zoom={initialZoom}
                        style={{height: "100vh", width: "100%", marginBottom:"-50px",zIndex:1}}
                      >
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                       
                        {this.state.listcenter.length >0 ?

                            <div> 
                              {this.state.listcenter.map((row) => (
                                <Marker key={row.idsocietycenter} position={JSON.parse('['+ row.latitude +',' +row.longitude+']')} icon={markerunsaid} data={row.idsocietycenter} eventHandlers={{
                                  click: (e) => {
                                    this.handleClick(e.target.options.data,row.idsocietycenter,row.idsocietyowner,row.name,row.latitude,row.longitude);
                                  },
                                }}></Marker>
                                ))}
                            </div>

                          :

                          null

                        }                      
                        
                        
                  </LeafletMap>

                </div>

                  }

                </div>
        
          );
        }
    
}

export default Map;
