
import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactPaginate from 'react-paginate';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Card from 'react-bootstrap/Card'
import { isExpired, decodeToken }from "react-jwt";
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

var listdata=[];

const src = "https://www.youtube.com/embed/f-2feMVSb8M";


class Jargon extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
  
        listjargon: [],
        modalupdate: false,
        modaldelete: false,
        idjargon:0,
        wordjargon:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        listjargoninit: [],
        filterdata:'',
        offset: 0,
        listjargonpage: [],
        perPage: 5,
        currentPage: 0,
        loadingdata: true,
      };

      this.handlePageClick = this.handlePageClick.bind(this);

  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listjargonpage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listjargon:slice
  })

  window.scrollTo(0, 0)

  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght == 0){
      this.setState({listjargon: this.state.listjargoninit})
      return;
    }
  
    const filteredItems = this.state.listjargoninit.filter((jargonlist) => {
      return (jargonlist.jargonword).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listjargon: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listjargon:slice,
                listjargonpage:filteredItems,
            })
  
    }else{
  
      this.setState({listjargon: [], listjargonpage:[]})
  
    }
   
    
  }


  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

    const myDecodedToken = decodeToken(tokenuser);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(tokenuser);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
    
    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {

        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

          document.location.href = "/Buycredit";

        }else{

          this.setState({
            loadingdata:false
          })
          
        }
        
    })

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    const response = await fetch(this.state.wbslink + 'jargon',{                       
      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") !== '0'){

        this.setState({ listjargon: data, listjargoninit: data,listjargonpage:data,loadershow: false})

        data.forEach((item)=>{
          listdata.push({ idjargon :item.idjargon ,jargonword:item.jargonword});
        })
      
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listjargoninit :data,
            listjargon:slice
        })
    
    }

    document.title = "JARGON";

    return;

    
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idjargon: 0,
    wordjargon: '',
    textloading:''
  });
  
}

addjargon(){


  if(this.state.wordjargon === '' && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter un mot.' });
    return
  
  }

  if(this.state.wordjargon === '' && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add word.' });
    return
  
  }

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("jargonword",this.state.wordjargon);

  fetch(this.state.wbslink + `jargon`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === '0'){

      this.setState({ errorform: true, errormessage: 'Error add name.' });

    }else{

      listdata.length= 0;

      this.setState({ listjargon: data, listjargoninit: data,listjargonpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idjargon :item.idjargon ,jargonword:item.jargonword});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listjargoninit :data,
          listjargon:slice
      })

      this.setState({
        idjargon: 0,
        wordjargon: '',
        errorform: false,
        errormessage: '',
      })
    }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  

}

showupdatejargon(idjargon,textupadte){

  this.setState({
    idjargon: idjargon,
    wordjargon: textupadte,
    modalupdate: true
  })

}

updatejargon(){

  if(this.state.wordjargon.length===0 && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter un mot.' });
    return
  
  }

  if(this.state.wordjargon.length===0 && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add word.' });
    return
  
  }

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("jargonword",this.state.wordjargon);

  formData.append('_method', 'PUT');

  fetch(this.state.wbslink + `jargon/`+this.state.idjargon,{                  
        
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === '0'){

      this.setState({ errorform: true, errormessage: 'Error add word.' });

    
    }else{

      listdata.length= 0;

      this.setState({ listjargon: data, listjargoninit: data,listjargonpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idjargon :item.idjargon ,jargonword:item.jargonword});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          //pageCount: Math.ceil(data.length / this.state.perPage),
          listjargoninit :data,
          listjargon:slice
      })

      this.setState({
        idjargon: 0,
        wordjargon: '',
        errorform: false,
        errormessage: '',
        modalupdate:false,
      })
    }
      
    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });    

}

showdeletejargon(idjargon,text){
  
  this.setState({
    idjargon: idjargon,
    wordjargon: text,
    modaldelete: true
  })

}

deletejargon(){

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append('_method', 'delete');

  fetch(this.state.wbslink + `jargon/`+this.state.idjargon,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length == 0){

      this.setState({ errorform: true, errormessage: 'Error add word.' });

    }else{

      listdata.length= 0;

      this.setState({ listjargon: data, listjargoninit: data,listjargonpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idjargon :item.idjargon ,jargonword:item.jargonword});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listjargoninit :data,
          listjargon:slice
      })

      this.setState({
        idjargon: 0,
        wordjargon: '',
        errorform: false,
        errormessage: '',
        modaldelete:false,
      })
    }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  

}
    
  render() {

          return (

                <div>

                  {this.state.loadingdata == true?

                    null
                  
                    :

                    <div className="App" style={{marginTop:'80px'}}>
                  
                    {this.state.tokenvalue === '' ?
                              
  
                              <Redirect push to="/Login"/>
                                
  
                              :
  
                              null
  
                      }
                          {/*
                          <iframe
                            width="560"
                            height="315"
                            src={src}
                            title="UNSAID"
                            frameborder="0"
                            allowFullScreen
                          />
                          */}

                          <div className='d-flex justify-content-center'>
                            <label>{this.state.lng ==="FR" ? 'Mot' : 'Word'}</label>
                          </div>
  
                          <div className='d-flex justify-content-center'>
                            <input style={{width:'300px'}} type="text" className="form-control" value={this.state.wordjargon} onChange={(e) => this.setState({ wordjargon: e.target.value})} />                    
                          </div>
  
                          <div className='d-flex justify-content-center'>
  
                              {this.state.errorform == true ? 
  
                              <Alert severity="error">
                                <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                                {this.state.errormessage}
                              </Alert>
  
                              :
  
                              null
  
                              }
  
                          </div>
  
                        <div className='d-flex justify-content-center'>
       
                          <button style={{margin:'20px'}}onClick={() => this.addjargon()} className="btn btn-success">{this.state.lng ==="FR" ? 'AJOUTER' : 'ADD'}</button> 
                                                               
                        </div> 
  
                    
                    {this.state.modalupdate === true ?
  
                    <Modal
                    
                    show={this.state.modalupdate}
                    onHide={ () => this.setState({modalupdate:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                        <div className="d-flex flex-column justify-content-center">
  
                            <label style={{marginLeft:'5%'}}>Title :</label>
                            <input style={{width:'90%', margin:'5%'}} type="text" className="form-control" value={this.state.wordjargon} onChange={(e) => this.setState({ wordjargon: e.target.value})} />
                                  
                            <div className='d-flex flex-row justify-content-center'>
                                        
                                  <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                  <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatejargon()} variant="success">{this.state.lng ==="FR" ? 'Modifier' : 'Update'}</Button>
  
                            </div>
  
                        </div>          
                      
                    </Modal.Body>
                    </Modal>
  
                    :
  
                    null
  
                    }
  
                    {this.state.modaldelete === true ?
  
                    <Modal
  
                    show={this.state.modaldelete}
                    onHide={ () => this.setState({modaldelete:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                                  
                      <div className="d-flex flex-row justify-content-center">
                                        
                          <div className="d-flex flex-column" style={{margin:"20px"}}>
                              
                              <div className="d-flex flex-column">
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Voulez-vous supprimer le mot' : 'Would you like to delete'}</p>
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.wordjargon} &nbsp;</p>
                                 
                                  <form>
  
                                  <div className='row text-white d-flex align-items-center'>
                                              
                                        <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deletejargon()} variant="danger">{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</Button>
  
                                  </div>
                                  
                                </form>
                              </div>
                              
                          </div>
  
                      </div>    
  
                    </Modal.Body>
                    </Modal>
  
                    :
  
                    null
  
                    }
  
                    <div className='centrervue' >
  
                    <input className="inputfind" style={{marginTop: isBrowser?"0px":"20px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>
  
                    <BrowserView>
    
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            
                            <TableCell >{this.state.lng ==="FR" ? 'Mot' : 'Word'}</TableCell>
                            <TableCell >{this.state.lng ==="FR" ? 'Mettre à jour' : 'Update'}</TableCell>
                            <TableCell >{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</TableCell>
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.listjargon.map((row) => (
                            <TableRow key={row.idjargon}>
  
                  
                              <TableCell >{row.jargonword}</TableCell>
                              <TableCell ><button type="submit" className="btn btn-primary btn-circle btn-xl" onClick={() => this.showupdatejargon(row.idjargon,row.jargonword)}><span><EditIcon /></span></button></TableCell>
                              <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeletejargon(row.idjargon,row.jargonword)}><span><DeleteIcon /></span></button></TableCell>
  
  
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
  
                        {this.state.listjargon.length>0?
  
                        <ReactPaginate
                        previousLabel={"<< prev"}
                        previousClassName={"prevClass"}
                        previousLinkClassName={"prevClass"}
                        nextLabel={"next >>"}
                        nextClassName={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
  
                        :
  
                        null
                        }
  
                    </BrowserView>
  
                    </div> 
  
                    <div>
  
                      <MobileView>
  
                        <div className='paginationmobile' style={{marginTop:"-5%"}}>
  
                        {this.state.listjargon.length>0?
  
                          <ReactPaginate
                          previousLabel={"<<"}
                          previousClassName={"prevClass"}
                          previousLinkClassName={"prevClass"}
                          nextLabel={">>"}
                          nextClassName={"next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}/>
  
                          :
  
                          null
                          
                        }
  
                        </div>
  
                        <div  style={{marginTop:"5%"}}>
                          <p style={{color:"transparent"}}>.</p>
                        </div>
  
                        <div style={{marginTop:"40px"}}>
  
                          {this.state.listjargon.map((row) => (
  
                          <Card style={{ width: '90%', margin:"5%"}}>
  
                          <Card.Body  key={row.idjargon} style={{backgroundColor: "#f5fffa"}}>
                            
                            <Card.Text>
  
                                <p style={{color:"black", marginTop:"10px"}}>{row.jargonword}</p>
                          
                            </Card.Text>
                              <div className='d-flex flex-row align-items-center justify-content-center'>
                                              
                                <button type="submit" className="btn btn-primary btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showupdatejargon(row.idjargon,row.jargonword)}><span><EditIcon /></span></button>
                                <button type="submit" className="btn btn-danger btn-circle btn-xl" style={{margin:'10px'}} onClick={() => this.showdeletejargon(row.idjargon,row.jargonword)}><span><DeleteIcon/></span></button>
  
                              </div>
                          </Card.Body>
                          </Card>
  
                          ))}
  
                        </div>
                        
                        </MobileView>
  
                    </div>
                 
                  </div>

                  }

                </div>
                  
          );
        }
    
}

export default Jargon;
